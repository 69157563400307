<template>
  <div class="uk-section">
    <div class="uk-container uk-margin-top uk-margin-medium-bottom" v-if="! loading">
      <div
        class="uk-grid uk-child-width-1-2@m in-testimonial-2"
      >
        <div class="uk-width-1-1@m uk-text-center uk-first-column">
          <h1 class="in-highlight">{{ content.intro.title }}</h1>
          <div v-html="content.intro.content"></div>
        </div>
      </div>
      <div class="uk-grid" v-for="(funder, i) in content.funders" :key="`funder-${i}`">
        <div class="uk-width-1-1">
          <div :class="`uk-card uk-card-${i%2 == 0 ? 'secondary' : 'primary'} get-funded-card`">
            <div class="uk-card-body">
              <div
                class="
                  uk-grid-divider
                  uk-grid
                "
              >
                <div class="uk-width-1-3">
                  <img :src="funder.logo" :alt="funder.title" style="height: 100px" />
                  <h2 class="mb-0">{{ funder.title }}</h2>
                  <a
                    :href="funder.link"
                    target="_blank"
                    class="
                      uk-button
                      uk-button-primary
                      bg-red
                      uk-margin-top
                    "
                    >GET STARTED NOW!</a
                  >
                </div>
                <div class="uk-width-2-3" v-html="funder.features"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      content: {},
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/pages/get-funded').then(response => {
        this.content = response.data.content
        this.loading = false
      })
    }
  }
};
</script>
