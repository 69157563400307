<template>
  <header>
    <!-- header content begin -->
    <div class="uk-section uk-padding-remove-vertical">
      <nav
        class="uk-navbar-container uk-navbar-dark uk-sticky"
        data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top;"
        style=""
      >
        <div class="uk-container uk-navbar" data-uk-navbar="">
          <div class="uk-navbar-left">
            <div class="uk-navbar-item">
              <!-- logo begin -->
              <router-link class="uk-logo" :to="{ name: 'public.home' }">
                <img
                  src="/logo.png"
                  data-src="/logo.png"
                  alt="logo"
                  width="146"
                  height="39"
                  data-uk-img=""
                />
              </router-link>
              <!-- logo end -->
              <!-- navigation begin -->
              <ul class="uk-navbar-nav uk-visible@m">
                <li>
                  <router-link :to="{ name: 'public.home' }">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'public.community' }">
                    Community
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'public.signals' }">
                    Signals
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'public.academy' }">
                    Online Learning
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'public.broker' }">
                    Top Brokers
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'public.get-funded' }">
                    Get Funded
                  </router-link>
                </li>
              </ul>
              <!-- navigation end -->
              <div class="uk-navbar-item in-mobile-nav uk-hidden@m">
                <a
                  id="mobile-nav-toggler"
                  class="uk-button"
                  href="#modal-full"
                  data-uk-toggle=""
                  aria-expanded="false"
                  ><i class="fas fa-bars"></i
                ></a>
                <div
                  id="modal-full"
                  class="uk-modal-full uk-modal"
                  data-uk-modal=""
                >
                  <div
                    class="
                      uk-modal-dialog uk-flex uk-flex-center uk-flex-middle
                    "
                    data-uk-height-viewport=""
                  >
                    <a class="uk-modal-close-full uk-button" id="uk-modal-close"
                      ><i class="fas fa-times"></i
                    ></a>
                    <div class="uk-width-large uk-padding-large">
                      <ul
                        class="uk-nav-default uk-nav-parent-icon uk-nav"
                        data-uk-nav=""
                      >
                        <li>
                          <router-link :to="{ name: 'public.home' }">
                            Home
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'public.community' }">
                            Community
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'public.signals' }">
                            Signals
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'public.academy' }">
                            Academy
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'public.broker' }">
                            Top Brokers
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'public.get-funded' }">
                            Get Funded
                          </router-link>
                        </li>
                      </ul>
                      <router-link
                        :to="{ name: 'login' }"
                        v-if="! is_authenticated"
                        class="
                          uk-button
                          uk-button-primary
                          uk-border-rounded
                          uk-align-center
                        "
                        style="margin-bottom: -12px"
                        >Log in<i
                          class="fas fa-sign-in uk-margin-small-left"
                        ></i
                      ></router-link>
                      <router-link
                        :to="{ name: 'register' }"
                        class="
                          uk-button
                          uk-button-primary
                          uk-border-rounded
                          uk-align-center
                        "
                        v-if="! is_authenticated"
                        style="margin-bottom: -12px"
                        >Sign Up<i
                          class="fas fa-sign-in uk-margin-small-left"
                        ></i
                      ></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-navbar-right" v-if="! is_authenticated">
            <div class="uk-navbar-item uk-visible@m in-optional-nav">
              <router-link
                :to="{ name: 'login' }"
                class="uk-button uk-button-text"
                >Log in<i
                  class="fas fa-arrow-circle-right uk-margin-small-left"
                ></i
              ></router-link>
              <router-link
                :to="{ name: 'register' }"
                class="uk-button uk-button-primary uk-border-rounded"
                >Sign up<i
                  class="fas fa-arrow-circle-right uk-margin-small-left"
                ></i
              ></router-link>
            </div>
          </div>
        </div>
      </nav>
      <div
        class="uk-sticky-placeholder"
        style="height: 92px; margin: 0px"
        hidden=""
      ></div>
    </div>
    <!-- header content end -->
  </header>
</template>

<script>
import '@/assets/js/public'

export default {
  computed: {
    is_authenticated() {
      return this.$store.getters.getAuthUser.name != null
    }
  }
};
</script>
